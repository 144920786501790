import React, { useContext, useEffect, useState, useRef } from "react";
import { Form, Input, Button, Space, Select, InputNumber, Image, Tooltip, Slider } from "antd";
import { MinusCircleOutlined, PlusOutlined, InfoCircleOutlined, MailOutlined } from "@ant-design/icons";
import { TokenAddressListContext } from "../../context/TokenAddressList";
import Moralis from "moralis";
import { notification, Checkbox } from "antd";
import { useHistory, Link } from "react-router-dom";

import mailIcon from "../../assets/mail.png";
import phoneIcon from "../../assets/phone.png";

import { Row, Col } from "react-bootstrap";

import party from "party-js";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_API_KEY, MIXPANEL_DEBUG } from "../../constants";
import TokenSelect from "../TokenSelect";
mixpanel.init(MIXPANEL_API_KEY, { debug: MIXPANEL_DEBUG, ignore_dnt: true });

export default function DisbursementInput(props) {
  const { tx, writeContracts, userAddress, localProvider, targetNetwork, mainnetProvider } = props;

  let history = useHistory();

  const UserEmail = Moralis.Object.extend("UserEmail");

  const { tokenList } = useContext(TokenAddressListContext);

  const [disbursementFormTokenAddresses, setDisbursementFormTokenAddresses] = useState([]);
  const [disbursementFormBeneficiaryAddresses, setDisbursementFormBeneficiaryAddresses] = useState([]);
  const [disbursementFormPercentages, setDisbursementFormPercentages] = useState([]);
  const [disbursementFormNftIds, setDisbursementFormNftIds] = useState([]);
  const [disbursementFormTokenStandards, setDisbursementFormTokenStandards] = useState([]);
  const [disburseTimeInputValue, setDisburseTimeInputValue] = useState(75);

  const emailRef = useRef(null);
  const didMount = useRef(false);
  const isChecked = useRef(false);
  const [blockIncrease, setBlockIncrease] = useState(0);
  const [dayTimer, setDayTimer] = useState(undefined);
  const [hourTimer, setHourTimer] = useState(0);
  const [minuteTimer, setMinuteTimer] = useState(0);

  const [agreed, setAgreed] = useState(false);

  /*
  async function getEmailFromMoralis() {
    const query = new Moralis.Query(UserEmail);

    query.equalTo("userAddress", userAddress);

    const results = await query.find();
    console.log(results[0]);
    if (results.length == 0) {
      console.log("no email");
    } else {
      const foundUser = results[0];
      const foundEmail = foundUser.get("userEmail");
      console.log(results[0], foundEmail);
      emailRef.current = foundEmail;
    }
  }
  */

  const onFinish = async values => {
    didMount.current = true;

    console.log(values);

    try {
      /*
      if (values.email) {
        emailRef.current = values.email;
      } else {
        await getEmailFromMoralis();
      }
      */

      let contract_address = [];
      let beneficiary_address = [];
      let percentages = [];
      let nftIds = [];
      let tokenStandards = [];

      for (const disbursements of values.disbursements) {
        if (disbursements.contract_address.toString().indexOf(" ") !== -1) {
          contract_address.push(
            disbursements.contract_address
              .toString()
              .substring(0, disbursements.contract_address.toString().indexOf(" ")),
          );
          beneficiary_address.push(disbursements.beneficiary_address);
          percentages.push(disbursements.percentage);
          nftIds.push(
            parseInt(
              disbursements.contract_address.substring(
                disbursements.contract_address.indexOf(" ") + 1,
                disbursements.contract_address.length,
              ),
            ),
          );
          tokenStandards.push("ERC721");
        } else {
          contract_address.push(disbursements.contract_address);
          beneficiary_address.push(await mainnetProvider.resolveName(disbursements.beneficiary_address));
          percentages.push(disbursements.percentage);
          nftIds.push(0);
          tokenStandards.push("ERC20");
        }
      }
      setDisbursementFormNftIds([...nftIds]);
      setDisbursementFormTokenStandards([...tokenStandards]);
      setDisbursementFormTokenAddresses([...contract_address]);
      setDisbursementFormPercentages([...percentages]);
      setDisbursementFormBeneficiaryAddresses([...beneficiary_address]);
    } catch (e) {
      //temp error handle
      window.alert(e);
    }

    mixpanel.track("Clicked on Create CoinWill Button", {
      page: "create",
    });
  };

  useEffect(() => {
    const createWill = async () => {
      tx(
        writeContracts.Will3Master.createWill3(
          disbursementFormTokenAddresses,
          disbursementFormPercentages,
          disbursementFormBeneficiaryAddresses,
          disbursementFormNftIds,
          disbursementFormTokenStandards,
          blockIncrease,
          { value: writeContracts.Will3Master.will3CreationCost() },
        ),
        update => {
          console.log("📡 Transaction Update:", update, emailRef.current, didMount.current);

          let etherscanNetwork = "";
          if (update.network) {
            etherscanNetwork = update.network + ".";
          }

          // let etherscanTxUrl = "https://" + etherscanNetwork + "etherscan.io/tx/";

          if (update && (update.status === "confirmed" || update.status === 1)) {
            const btn = (
              <Button style={{ alignContent: "center" }} type="primary" size="small">
                <a href="/dashboard">Dashboard</a>
              </Button>
            );

            // add confetti dropping here
            party.confetti(document.getElementById("particle-screen"), {
              count: 400,
              color: [party.Color.fromHex("#FFFFFF"), party.Color.fromHex("#0B63BC")],
              shapes: ["rectangle"],
              size: 1.25,
              speed: party.variation.range(1, 1500),
            });

            notification.info({
              message: "Your CoinWill has been created",
              description: "Redirecting to Your Dashboard...",
              placement: "topRight",
              duration: 6,
            });

            setTimeout(() => {
              notification.destroy();
              history.push("/dashboard");
            }, 6000);

            mixpanel.track("CoinWill Created Successfully", {
              page: "create",
            });
          }

          if (update && (update.status === "confirmed" || update.status === 1) && emailRef.current !== null) {
            console.log(`Run Moralis Cloud Function with ${emailRef.current}`);

            const sendEmail = async () => {
              try {
                await Moralis.Cloud.run("emailSubscribe", {
                  email: emailRef.current,
                  address: userAddress,
                  txHash: update.hash,
                });

                emailRef.current = null;
              } catch (e) {
                console.log(e);
              }
            };
            sendEmail();
          }
        },
      );
    };

    if (didMount.current) {
      createWill();
      didMount.current = false;
    }
  }, [disbursementFormBeneficiaryAddresses]);

  /*
  useEffect(() => {
    const clickAmount = 2;
    for (let i = 0; i < clickAmount; i += 1) {
      //document.querySelector(".add-rule-button").click();
      // add();
    }
    window.scrollTo(0, 0);
  }, []);
  */

  let timeLookupTimeout;

  const onChange = async value => {
    setDayTimer(null);
  };

  function onDisburseSymbolChange(value) {
    // console.log("onDisburseSymbolChange");
    // console.log(value);
  }

  const onAfterChange = async value => {
    let minBlockIncrease = await writeContracts.Will3Master.minBlockIncrease();
    let maxBlockIncrease = await writeContracts.Will3Master.maxBlockIncrease();
    minBlockIncrease = minBlockIncrease.toNumber();
    maxBlockIncrease = maxBlockIncrease.toNumber();
    const adjustedValue =
      value === 0
        ? minBlockIncrease
        : Math.floor((maxBlockIncrease - minBlockIncrease) * (value * 0.01) + minBlockIncrease);
    setBlockIncrease(adjustedValue);
    setDayTimer(null);

    const url = `${targetNetwork.blockCountdownUrl}${
      adjustedValue + localProvider._lastBlockNumber
    }&apikey=T1FYGW282A3Q6T1IJDY4NDIWTM6ZK7BB2I`;

    try {
      fetch(url)
        .then(res => {
          if (!res.ok) {
            throw new Error(res.statusText);
          } else {
            return res.json();
          }
        })
        .then(res => {
          if (res.status === "1") {
            let seconds = Number(res.result.EstimateTimeInSec);
            const days = Math.floor(seconds / (3600 * 24));
            seconds -= days * 3600 * 24;
            const hours = Math.floor(seconds / 3600);
            seconds -= hours * 3600;
            const minutes = Math.floor(seconds / 60);
            seconds -= minutes * 60;
            setDayTimer(days);
            setHourTimer(hours);
            setMinuteTimer(minutes);
          } else if (res.result === "Error! Block number has already past") {
            setDayTimer(0);
            setHourTimer(0);
            setMinuteTimer(0);
          } else {
            console.log(res);
            console.log(url);
          }
        });
    } catch (e) {
      window.alert(e);
    }

    setDisburseTimeInputValue(value);
  };

  return (
    <div>
      <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
        <div className="create-will3-disbursement-section">
          <div className="create-will3-section">
            <h2 className="create-section-header-title">
              Create Instructions{/*<span className="required-notification">*required</span>*/}
              {/*
              <Tooltip
                placement="top"
                title="Assets listed in disbursements remain in your wallet upon creating a CoinWill. When your disbursement date has passed, your CoinWill will automatically be disbursed to your beneficiaries."
              >
                <InfoCircleOutlined style={{ verticalAlign: "0.125em", marginBottom: "12px", fontSize: "16px" }} />
              </Tooltip>
              */}
            </h2>
            <div className="blue-line-divider" />
            <h6 className="create-section-description">
              Your CoinWill is made up of Instructions. These instructions define which assets and their proportions
              will be disbursed to your beneficiaries when your CoinWill is carried out.
            </h6>
            <Form.List name="disbursements">
              {(fields, { add, remove }) => (
                <div>
                  <div style={{ marginTop: 25 }}></div>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        marginBottom: 0,
                        justifyContent: "center",
                        width: "100%",
                        color: "black",
                        minWidth: 600,
                        border: "0.5px solid rgba(0, 103, 199, 0.5)",
                        borderRadius: "8px",
                        boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.04)",
                      }}
                      align="baseline"
                    >
                      To
                      <Form.Item
                        {...restField}
                        name={[name, "beneficiary_address"]}
                        rules={[{ required: true, message: "Enter valid address or ENS" }]}
                        style={{ marginBottom: "0px !important" }}
                      >
                        <Input placeholder="Wallet address or ENS name" className="beneficiary-wallet-input" />
                      </Form.Item>
                      I bequeath
                      <Form.Item name={[name, "percentage"]} initialValue={100}>
                        <InputNumber
                          min={0}
                          max={100}
                          value={100}
                          formatter={value => `${value}%`}
                          parser={value => value.replace("%", "")}
                          style={{ color: "black", maxWidth: 75 }}
                        />
                      </Form.Item>
                      <Form.Item name={[name, "contract_address"]} className="select-asset-dropdown">
                        {/* <TokenSelect /> */}
                        <Select
                          placeholder={<p style={{ color: "#999999", fontSize: 14 }}>Select asset</p>}
                          onChange={onDisburseSymbolChange}
                        >
                          {tokenList.map(token => (
                            <Select.Option
                              value={`${token.contract_address}${token.token_id ? ` ${token.token_id}` : ""}`}
                              key={`${token.contract_ticker_symbol} #${token.token_id}`}
                            >
                              {token.contract_ticker_symbol} {token.token_id ? `#${token.token_id}` : ``}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Tooltip title="Remove Instruction">
                        <MinusCircleOutlined
                          style={{ display: "block" }}
                          onClick={() => {
                            remove(name);
                            mixpanel.track("Clicked Remove CoinWill Instruction Button", {
                              page: "create",
                            });
                          }}
                        />
                      </Tooltip>
                    </Space>
                  ))}
                  <Form.Item style={{ textAlign: "center" }}>
                    <Button
                      className="add-rule-button"
                      style={{ marginTop: 32 }}
                      onClick={() => {
                        add();
                        mixpanel.track("Clicked Add CoinWill Instruction Button", {
                          page: "create",
                        });
                      }}
                      block
                    >
                      + Add Instruction
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </div>
        </div>
        <div className="create-will3-blue-section">
          <div className="create-will3-section">
            <h2 className="create-section-header-title">
              Set Trigger{/*<span className="required-notification">*required</span>*/}
            </h2>
            <div className="blue-line-divider" />
            <h6 className="create-section-description">
              Use the slider below to set the time until your next Proof of Life Update. If this time limit is passed
              without a Proof of Life Update, your assets will be disbursed to your beneficiaries automatically. Proof
              of Life updates can be submitted from your CoinWill dashboard.
            </h6>
            <div style={{ margin: "48px 0px 0px 0px" }}>
              <Slider
                step={0.05}
                tooltipVisible={false}
                onChange={onChange}
                onAfterChange={onAfterChange}
                defaultValue={0}
              />
              <h6 style={{ marginTop: 32, fontWeight: 400 }}>
                Approximate Time:{" "}
                {dayTimer !== null
                  ? `${dayTimer > 0 ? `${dayTimer} Day${dayTimer > 1 ? "s" : ""}, ` : ``}
                  ${hourTimer > 0 ? `${hourTimer} Hours and ` : ``}${
                      minuteTimer > 0 || hourTimer > 0 || dayTimer > 0 ? `${minuteTimer} Minutes` : `Less than a minute`
                    }`
                  : ` Release to Calculate`}
              </h6>
            </div>
          </div>
        </div>
        <div>
          <div className="create-will3-section">
            <h2>Notifications</h2>
            <div style={{ marginTop: 36, marginBottom: 36 }}>
              <h6
                className="create-section-description"
                style={{ minWidth: "500px", maxWidth: "1000px", marginBottom: 0 }}
              >
                Set up notifications and never miss a Proof of Life Update!
              </h6>
            </div>
            <div style={{ marginBottom: 12, justifyContent: "center" }}>
              <Tooltip title="Coming Soon">
                <Form.Item
                  tooltip="Enable reminder emails to get notifications about when your CoinWill is disbursable and how to increase your CoinWill's disbursement block."
                  label=""
                  name="email"
                  className="reminder-input-section"
                >
                  <Input
                    placeholder="Your Email Address"
                    prefix={<img style={{ height: 20, marginRight: 20, marginLeft: 10 }} src={mailIcon} />}
                    className="user-reminder-input"
                    type={"email"}
                    disabled
                  />
                </Form.Item>
              </Tooltip>

              <Tooltip title="Coming Soon">
                <Form.Item
                  tooltip="Enable reminder text messages to get notifications about when your CoinWill is disbursable and how to increase your CoinWill's disbursement block."
                  label=""
                  name="phone"
                  className="reminder-input-section"
                >
                  <Input
                    placeholder="Your Phone Number"
                    prefix={<img style={{ height: 20, marginRight: 20, marginLeft: 10 }} src={phoneIcon} />}
                    controls={false}
                    className="user-reminder-input"
                    type={"text"}
                    disabled
                  />
                </Form.Item>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="create-will3-blue-section create-will3-agreement-section">
          <Form.Item style={{ width: "100%" }}>
            <p className="will3-agreement">
              By creating a CoinWill, you verify the information you have provided constitutes your wishes to be carried
              out on your behalf by CoinWill. You agree that CoinWill will not be held liable for any inaccessible funds
              whether they be related to CoinWill's service or lack thereof. Including your CoinWill in your traditional
              estate planning may be important for the future. Please include the following language related to your
              crypto assets in your traditional estate planning documents:
            </p>
            <p className="will3-italics">
              I certify that the smart contracts deployed by CoinWill are the executor of my crypto and Web3 digital
              assets for the following wallet address: {userAddress}. In accordance with my wishes, my crypto, NFTs and
              other Web3, digital assets are to be disbursed by smart contracts deployed by CoinWill.
            </p>
            <p className="will3-checkbox">
              <Checkbox
                onChange={e => {
                  setAgreed(e.target.checked);
                }}
                style={{ color: "black", fontWeight: "400" }}
              >
                I agree to the language above and the CoinWill{" "}
                <a href="/tou" target="_blank">
                  Terms and Conditions{" "}
                </a>
                and{" "}
                <a href="/pp" target="_blank">
                  Privacy Policy
                </a>
              </Checkbox>
            </p>
            <Button
              className={agreed ? "connect-wallet-button" : "connect-wallet-button-disabled"}
              style={{ height: 40, width: 150, borderRadius: 9 }}
              size="medium"
              htmlType="submit"
              block
              disabled={!agreed}
            >
              Create CoinWill
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
