import React, { Fragment, useState } from "react";
import { Tooltip, Button, Spin, Form, Input, Row, Col, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons/lib/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import useWindowSize from "./hooks/useWindowHook";

const disbursementHeaderImage = "./../assets/disburse-header-image.png";

export default function ReleaseDisbursement(props) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { writeContracts, localProvider, tx } = props;
  const size = useWindowSize();
  const { Title } = Typography;
  const [willExists, setWillExists] = useState(false);
  const [willDisbursed, setWillDisbursed] = useState(false);
  const [searchedAddress, setSearchedAddress] = useState("");
  const [block, setBlock] = useState(0);

  const onFinish = values => {
    console.log("Success:", values);
    const deceasedAddress = values.deceasedAddress;
    console.log("about to send disbursements");
    const sendDisbursements = async () => {
      tx(writeContracts.Will3Master.sendDisbursements(deceasedAddress), update => {
        console.log("📡 Transaction Update:", update);

        if (update && update.data.code === 3) {
          notification.destroy();

          notification.info({
            className: "frontendModal",
            message: "Will3 Release Fail",
            description: `Disbursement Block has not passed`,
            placement: "topLeft",
            duration: 4,
            icon: <Spin indicator={antIcon} />,
          });
        }

        if (update && (update.status === "confirmed" || update.status === 1)) {
          notification.info({
            className: "frontendModal",
            message: "Will3 Release Success!",
            description: "Funds have been sent to beneficiaries",
            placement: "topLeft",
            duration: 4,
            icon: <Spin indicator={antIcon} />,
          });
        }
      });
    };
    sendDisbursements();
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  const onChange = values => {
    console.log("new address");
    const checkAddress = values.target.value;
    setSearchedAddress(checkAddress);
    const getWill3 = async () => {
      if (!writeContracts.Will3Master) {
        return setWillExists(false);
      }
      const result = await writeContracts.Will3Master.getWill3(checkAddress);
      const disbursementBlock = await writeContracts.Will3Master.addressToDisburseBlock(checkAddress);

      console.log(result);

      if (result.length !== 0) {
        const isWill3Disbursed = await writeContracts.Will3Master.addressToWill3Disbursed(checkAddress);
        if (isWill3Disbursed) {
          setWillDisbursed(true);
          setWillExists(false);
        } else {
          setWillExists(true);
        }
        setBlock(disbursementBlock);
        setSearchedAddress(checkAddress);
      } else {
        setBlock(0);
        setWillExists(false);
        setWillDisbursed(false);
      }
    };

    if (values.target.value.length === 42) {
      getWill3();
    } else {
      setBlock(0);
      setWillExists(false);
      setWillDisbursed(false);
    }
  };

  return (
    <Fragment>
      <div className="manual-disburse-page">
        <div style={{ marginBottom: 150, marginTop: 175 }}></div>

        <Form name="sign" onChange={onChange} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ width: "90%", margin: "auto" }}>
            <Col span={1}></Col>
            <Col span={11}>
              <div>
                <h1 style={{ fontWeight: "bold", marginTop: 50 }}>Manual Disbursement</h1>
              </div>
              <Title level={5} style={{ color: "black", fontWeight: 400 }}>
                CoinWill is designed to disburse automatically after a CoinWill's disbursement block has passed. If an
                automated disbursement fails to function due to network conditions, it can be triggered manually by
                entering the owner's wallet address below.
                <br />
                <br />
                <h6 style={{ fontWeight: 700 }}>
                  This will ONLY work if the disbursal block for that CoinWill has passed
                </h6>
              </Title>
              <Form.Item
                style={{ minWidth: 350, maxWidth: 425, marginLeft: "auto", marginRight: "auto", marginTop: 30 }}
                name="deceasedAddress"
                rules={[
                  { required: true },
                  { type: "string", min: 42, max: 42, message: "Please input a valid wallet address!" },
                ]}
              >
                <Input placeholder="Wallet address of deceased owner" onChange={onChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <img className="disbursement-header-image" src={disbursementHeaderImage} />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={16}>
              {willDisbursed ? (
                <div
                  style={{
                    padding: "40px 0px",
                    margin: "40px auto",
                    marginTop: 100,
                    paddingTop: "60px",
                    width: "100%",
                    backgroundColor: "#EBF7FC",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <h6 style={{ marginBottom: 4 }}>This Will3 has already been disbursed </h6>
                  </div>
                </div>
              ) : (
                ""
              )}
              {willExists ? (
                <div
                  style={{
                    padding: "40px 0px",
                    margin: "40px auto",
                    marginTop: 100,
                    paddingTop: "60px",
                    width: "100%",
                    backgroundColor: "#EBF7FC",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <h6 className="manual-disburse-text" style={{ fontWeight: "bold" }}>
                      {searchedAddress}
                    </h6>
                    {block <= localProvider._lastBlockNumber ? (
                      <h6 className="manual-disburse-text">The disbursal block for this wallet address has passed</h6>
                    ) : (
                      <h6 className="manual-disburse-text">
                        The disbursal block for this wallet address has NOT passed yet
                      </h6>
                    )}
                    <h6 className="manual-disburse-text">
                      {block
                        ? `Current Block: ${localProvider._lastBlockNumber.toLocaleString(
                            "en-US",
                          )} | Disbursal Block: ${block.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : ``}{" "}
                      <br />
                    </h6>
                    {block <= localProvider._lastBlockNumber ? (
                      <Form.Item>
                        <Button
                          style={{
                            margin: "5px",
                            border: "1px solid #009BDE",
                            boxShadow: "0px 3px 2px rgba(0, 0, 0, 0.25)",
                            borderRadius: 6,
                          }}
                          className="connect-wallet-button"
                          shape="default"
                          size="large"
                          htmlType="submit"
                        >
                          Disburse Will3
                        </Button>
                      </Form.Item>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Form>
        <div style={{ marginBottom: 80 }}></div>
      </div>
    </Fragment>
  );
}
