import React, { Fragment, useState, useEffect, useContext } from "react";
import { Tooltip, Button, Spin, Form, InputNumber, Row, Col, Progress, Input } from "antd";
import { Table } from "react-bootstrap";
import { InfoCircleOutlined } from "@ant-design/icons/lib/icons";
import { BrowserRouter, Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import useWindowSize from "./hooks/useWindowHook";
import axios from "axios";

import mailIcon from "./../assets/mail.png";
import phoneIcon from "./../assets/phone.png";

import Address from "./Address";

import will3DashIcon from "./../assets/will3Dash.png";
import will3DashIconSelected from "./../assets/will3DashSelected.png";

import proofOfLifeIcon from "./../assets/proofOfLife.png";
import proofOfLifeIconSelected from "./../assets/proofOfLifeSelected.png";

import notificationsIcon from "./../assets/notifications.png";
import notificationsIconSelected from "./../assets/notificationsSelected.png";

import cancelWill3Icon from "./../assets/cancelWill3.png";
import cancelWill3IconSelected from "./../assets/cancelWill3Selected.png";

import { COVALENT_API_KEY, erc20Abi, erc721Abi, MIXPANEL_API_KEY, MIXPANEL_DEBUG } from "../constants";
const { ethers } = require("ethers");

import mixpanel from "mixpanel-browser";
mixpanel.init(MIXPANEL_API_KEY, { debug: MIXPANEL_DEBUG, ignore_dnt: true });

export default function Dashboard(props) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [loading, setLoading] = useState(false);
  const {
    writeContracts,
    address,
    userAddress,
    chainId,
    localProvider,
    tx,
    blockExplorer,
    appId,
    serverUrl,
    route,
    will3Exists,
    targetNetwork,
    addressExplorer,
    mainnetProvider,
    dayTimerSoonThreshold,
    signer,
  } = props;
  const [will, setWill] = useState([]);
  const [dayTimer, setDayTimer] = useState(0);
  const [hourTimer, setHourTimer] = useState(0);
  const [minuteTimer, setMinuteTimer] = useState(0);
  const [block, setBlock] = useState(0);
  const [canIncreaseBlock, setCanIncreaseBlock] = useState(false);
  const size = useWindowSize();
  const [isWill3Disbursed, setIsDisbursed] = useState(false);
  const [dashboardRoute, setDashboardRoute] = useState("will3Dash");
  const [tokens, setTokens] = useState([]);
  // console.log("Write contracts");
  // console.log(writeContracts);
  const onFinish = values => {
    console.log("Success:", values);
    const increaseBlock = writeContracts.Will3Master.maxBlockIncrease(); // values.increaseBlock;
    const increaseDisbursementBlock = async () => {
      tx(
        writeContracts.Will3Master.increaseDisbursementBlock(increaseBlock, {
          value: writeContracts.Will3Master.will3UpdateCost(),
        }),
        update => {
          console.log("📡 Transaction Update:", update);

          if (update && (update.status === "confirmed" || update.status === 1)) {
            notification.info({
              className: "frontendModal",
              message: "You have re-signed your CoinWill successfully",
              description: "Reloading Dashboard in 10 seconds",
              placement: "topLeft",
              duration: 8,
              icon: <Spin indicator={antIcon} />,
            });

            setTimeout(() => {
              notification.destroy();
              history.go(0);
            }, 10000);
          }
        },
      );

      mixpanel.track("Clicked Proof of Life Update Button", {
        page: "dashboard",
      });
    };
    increaseDisbursementBlock();
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  const removeWill3 = values => {
    const removeMyWill3 = async () => {
      tx(writeContracts.Will3Master.removeWill3(), update => {
        console.log("📡 Transaction Update:", update);

        if (update && (update.status === "confirmed" || update.status === 1)) {
          notification.info({
            className: "frontendModal",
            message: "CoinWill Cancelled",
            description: "Your CoinWill Instructions have been Removed",
            placement: "topLeft",
            duration: 5,
            icon: <Spin indicator={antIcon} />,
          });

          setTimeout(() => {
            notification.destroy();
            history.go(0);
          }, 6);
        }
      });
    };
    removeMyWill3();
  };

  useEffect(() => {
    setLoading(true);
    if (writeContracts.Will3Master && userAddress) {
      const getWill3 = async () => {
        const result = await writeContracts.Will3Master.getWill3(userAddress);

        if (result.length !== 0) {
          const wills = [];
          for (const disbursements of result) {
            wills.push({
              assetAddress: disbursements.assetAddress,
              percentageOfHoldings: disbursements.percentageOfHoldings._hex,
              receivingAddress: disbursements.receivingAddress,
              nftId: disbursements.nftId,
              tokenStandard: disbursements.tokenStandard,
            });
          }
          const tokenAddresses = wills.map(disbursement => disbursement.assetAddress);
          console.log(wills);
          let requests = [];

          for (let i = 0; i < wills.length; i++) {
            console.log("covalent url");
            requests[i] = axios.get(
              `https://api.covalenthq.com/v1/${chainId}/tokens/${wills[i].assetAddress}/nft_metadata/123/?quote-currency=USD&format=JSON&key=${COVALENT_API_KEY}`,
            );
          }

          let resArray = [];
          await axios
            .all(requests)
            .then(
              axios.spread((...responses) => {
                console.log(responses);
                responses.forEach(el => {
                  const item = el.data.data.items[0];
                  console.log(item);
                  resArray.push(item);
                });
              }),
            )
            .catch(errors => {
              // react on errors.
              console.error(errors);
            });

          console.log(resArray);
          for (let i = 0; i < resArray.length; i++) {
            wills[i].contract_name = resArray[i].contract_name;
          }

          // const options = { chain: chainId, addresses: tokenAddresses };
          /*
          for (let i = 0; i < resArray.length; i++) {
            if (tokenMetadata[i].name === "") {
              try {
                const nftOptions = { chain: chainId, address: tokenMetadata[i].address };
                const metadata = await Moralis.Web3API.token.getNFTMetadata(nftOptions);
                tokenMetadata[i].name = metadata.contract_name;
              } catch (e) {
                console.log(e);
              }
            }
          }
          for (const will of wills) {
            const result = tokenMetadata.filter(
              token => token.address.toLowerCase() == will.assetAddress.toLowerCase(),
            );
            if (result) {
              will.name = result[0].name;
            }
          }
          */

          const disbursementBlock = await writeContracts.Will3Master.addressToDisburseBlock(userAddress);
          const isDisbursed = await writeContracts.Will3Master.addressToWill3Disbursed(userAddress);
          setIsDisbursed(isDisbursed);
          const url = `${targetNetwork.blockCountdownUrl}${disbursementBlock}&apikey=T1FYGW282A3Q6T1IJDY4NDIWTM6ZK7BB2I`;

          try {
            fetch(url)
              .then(res => {
                if (!res.ok) {
                  throw new Error(res.statusText);
                } else {
                  return res.json();
                }
              })
              .then(res => {
                if (res.status === "1") {
                  let seconds = Number(res.result.EstimateTimeInSec);
                  const days = Math.floor(seconds / (3600 * 24));
                  seconds -= days * 3600 * 24;
                  const hours = Math.floor(seconds / 3600);
                  seconds -= hours * 3600;
                  const minutes = Math.floor(seconds / 60);
                  seconds -= minutes * 60;
                  setDayTimer(days);
                  setHourTimer(hours);
                  setMinuteTimer(minutes);
                } else if (res.result === "Error! Block number already pass") {
                  setDayTimer(0);
                  setHourTimer(0);
                  setMinuteTimer(0);
                } else {
                  window.alert("an unknown error has occured");
                }
              });

            const blockIncreaseThreshold = await writeContracts.Will3Master.blockIncreaseThreshold();
            const currentBlock = localProvider._lastBlockNumber;
            if (currentBlock >= disbursementBlock - blockIncreaseThreshold) {
              setCanIncreaseBlock(true);
            }
          } catch (e) {
            window.alert(e);
          }

          setBlock(disbursementBlock);
          setWill(wills);
          setLoading(false);
          console.log("the wills are here!");
          console.log(wills);
        } else {
          setLoading(false);
        }
      };
      getWill3();
    }
  }, [userAddress, route, will3Exists]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [route]);

  useEffect(async () => {
    let tokens;
    let isSubscribed = true;

    const fetchTokens = async () => {
      await (async () => {
        try {
          // https://api.covalenthq.com/v1/1/address/demo.eth/balances_v2/?key=ckey_8b8f75df462c418a847c1b3607c
          console.log("covalent url");
          const lookupUrl = `https://api.covalenthq.com/v1/${chainId}/address/${userAddress}/balances_v2/?format=JSON&nft=true&no-nft-fetch=true&key=${COVALENT_API_KEY}`;
          console.log(lookupUrl);
          await axios.get(lookupUrl).then(response => {
            tokens = response.data.data.items;
            // setnativeBalance(ethers.utils.formatEther(tokens[0].balance));
            tokens.splice(0, 1);
            console.log(tokens);
            //setTokens(tokens);
            //updateTokenList(tokens);
          });
          console.log(tokens);
          console.log("------------");
        } catch (e) {
          if (e) {
            console.log(e);
          }
        }
      })();

      const checkAllAllowances = async () => {
        if (tokens) {
          tokens.forEach(async token => {
            const checkWill3TokenAllowance = async () => {
              try {
                let tempContract;
                let result;
                if (token.contract_type === "ERC721") {
                  tempContract = new ethers.Contract(token.token_address, erc721Abi, signer);
                  result = await makeCall("isApprovedForAll", tempContract, [userAddress, address]);
                  console.log("result");
                  console.log(result);
                  token.isApproved = result;
                } else {
                  tempContract = new ethers.Contract(token.token_address, erc20Abi, signer);
                  result = await makeCall("allowance", tempContract, [userAddress, address]);
                  console.log("result");
                  console.log(result);
                  if (result._hex !== "0x00") {
                    token.isApproved = true;
                    const approvedAmount = result.toString();
                    const tokenBalance = token.balance / Math.pow(10, token.decimals);
                    const decimal = approvedAmount / tokenBalance;
                    const finalValue = decimal * 100;
                    token.percentageApproved = finalValue <= 100 ? finalValue.toString() : 100; // (decimal * 100).toString();
                  } else {
                    token.isApproved = false;
                  }
                }

                console.log(token);
              } catch (e) {
                console.log(e);
              }
            };
            await checkWill3TokenAllowance();
          });
        }
      };

      await checkAllAllowances();
    };

    await fetchTokens().catch(console.error);

    return () => (isSubscribed = false);
  }, [userAddress]);

  return (
    <Fragment>
      <Row>
        <Col span={4} className="dashboard-navigation-section">
          <div className="dashboard-navigation-section-header-container">
            <a
              className={
                dashboardRoute == "will3Dash"
                  ? "dashboard-navigation-section-header dashboard-navigation-section-header-selected no-select"
                  : "dashboard-navigation-section-header no-select"
              }
              onClick={() => {
                setDashboardRoute("will3Dash");
              }}
            >
              <img
                className="dashboard-header-icon"
                src={dashboardRoute == "will3Dash" ? will3DashIconSelected : will3DashIcon}
              />
              Instructions
            </a>
          </div>
          <div className="dashboard-navigation-section-header-container">
            <a
              className={
                dashboardRoute == "proofOfLife"
                  ? "dashboard-navigation-section-header dashboard-navigation-section-header-selected no-select"
                  : "dashboard-navigation-section-header no-select"
              }
              onClick={() => {
                setDashboardRoute("proofOfLife");
              }}
            >
              <img
                className="dashboard-header-icon"
                src={dashboardRoute == "proofOfLife" ? proofOfLifeIconSelected : proofOfLifeIcon}
              />{" "}
              Proof of Life
            </a>
          </div>
          <div className="dashboard-navigation-section-header-container">
            <a
              className={
                dashboardRoute == "notifications"
                  ? "dashboard-navigation-section-header dashboard-navigation-section-header-selected no-select"
                  : "dashboard-navigation-section-header no-select"
              }
              onClick={() => {
                setDashboardRoute("notifications");
              }}
            >
              <img
                className="dashboard-header-icon"
                src={dashboardRoute == "notifications" ? notificationsIconSelected : notificationsIcon}
              />{" "}
              Notifications
            </a>
          </div>
          <div className="dashboard-navigation-section-header-container">
            <a
              className={
                dashboardRoute == "cancelWill3"
                  ? "dashboard-navigation-section-header dashboard-navigation-section-header-selected no-select"
                  : "dashboard-navigation-section-header no-select"
              }
              onClick={() => {
                setDashboardRoute("cancelWill3");
              }}
            >
              <img
                className="dashboard-header-icon"
                src={dashboardRoute == "cancelWill3" ? cancelWill3IconSelected : cancelWill3Icon}
              />{" "}
              Cancel CoinWill
            </a>
          </div>
        </Col>
        <Col span={20} className="dashboard-section-master">
          <div style={{ minHeight: 1200 }}>
            {loading ? (
              <Spin style={{ marginTop: 120 }} indicator={antIcon} />
            ) : (
              <div>
                {/*
            <div className="dashboard-header">
              <div className="dashboard-header-inside">
                <h1 style={{ color: "white", fontWeight: "bold" }}>Your CoinWill</h1>
              </div>
            </div>
            */}
                <div style={{ paddingTop: 80 }} />
                {userAddress && will.length > 0 ? (
                  <div
                    className="dashboard-section-individual"
                    style={{ width: "100%", margin: "0px auto 24px auto", border: "0.5px solid #07014F" }}
                  >
                    {will.length > 0 ? (
                      <Fragment>
                        {dashboardRoute == "will3Dash" ? (
                          <div className="will3-dashboard-section" style={{ paddingBottom: 2, paddingTop: 36 }}>
                            <h2 style={{ textAlign: "left", marginBottom: 10 }}>CoinWill Instructions</h2>
                            <Row>
                              <Col span={12}>
                                {isWill3Disbursed ? (
                                  <p className="time-expired" style={{ textAlign: "left", color: "#07014F" }}>
                                    Your CoinWill has been disbursed in accordance with your wishes
                                  </p>
                                ) : (
                                  <p style={{ textAlign: "left", color: "#07014F" }}>
                                    {block
                                      ? `Current Block: ${localProvider._lastBlockNumber.toLocaleString(
                                          "en-US",
                                        )} | Disbursal Block: ${block.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                      : ``}
                                  </p>
                                )}
                              </Col>
                              <Col span={12} style={{ textAlign: "right" }}>
                                {isWill3Disbursed ? (
                                  <></>
                                ) : dayTimer == 0 && hourTimer == 0 && minuteTimer == 0 ? (
                                  <span style={{ margin: "auto" }}>
                                    <a href="/disburse">
                                      <Button
                                        className="connect-wallet-button"
                                        shape="round"
                                        size="large"
                                        htmlType="submit"
                                        style={{ color: "black" }}
                                        onClick={"/disburse"}
                                      >
                                        Ready for Disbursal
                                      </Button>
                                    </a>
                                  </span>
                                ) : (
                                  <p style={{ textAlign: "right", margin: "auto", color: "#07014F" }}>
                                    Est. Time Remaining —{" "}
                                    <span
                                      style={{ color: "#0A64BC" }}
                                      className={
                                        dayTimer == 0 && hourTimer == 0 && minuteTimer == 0
                                          ? "time-expired"
                                          : dayTimer < dayTimerSoonThreshold
                                          ? "time-expired-soon"
                                          : ""
                                      }
                                    >
                                      {dayTimer} Day{dayTimer > 1 ? "s" : ""}, {hourTimer} Hours and {minuteTimer}{" "}
                                      Minutes
                                    </span>
                                  </p>
                                )}
                              </Col>
                            </Row>

                            <div bordered="true" className="dashboard-table">
                              <div style={{ margin: "auto 8px" }}>
                                <Row>
                                  <Col className="dashboard-table-header" span={9}>
                                    Asset
                                  </Col>
                                  <Col className="dashboard-table-header" span={9}>
                                    Amount
                                  </Col>
                                  <Col className="dashboard-table-header" span={6}>
                                    Beneficiary Address
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                {will.map((disbursement, key) => (
                                  <Row className="dashboard-table-entry" key={`${disbursement.receivingAddress}${key}`}>
                                    <Col span={9}>
                                      <a
                                        href={`${blockExplorer}token/${disbursement.assetAddress}`}
                                        target="_blank"
                                        style={{ textDecoration: "underline" }}
                                      >
                                        {disbursement.contract_name}
                                        {disbursement.tokenStandard == "ERC721" ? ` #${disbursement.nftId}` : ""}
                                      </a>
                                    </Col>
                                    <Col span={9}>
                                      {disbursement.tokenStandard == "ERC721"
                                        ? 1
                                        : `${Number(disbursement.percentageOfHoldings)}%${
                                            tokens.length > 0
                                              ? ` (approx. ${(
                                                  (tokens.filter(
                                                    token =>
                                                      token.token_address.toLowerCase() ==
                                                      disbursement.assetAddress.toLowerCase(),
                                                  )[0].balance *
                                                    (disbursement.percentageOfHoldings / 100)) /
                                                  Math.pow(10, 18)
                                                ).toFixed(4)})`
                                              : ""
                                          }`}
                                    </Col>
                                    <Col span={6}>
                                      <Address
                                        address={disbursement.receivingAddress}
                                        ensProvider={mainnetProvider}
                                        addressExplorer={addressExplorer}
                                        fontSize={14}
                                        darkBg={true}
                                      />
                                    </Col>
                                  </Row>
                                ))}
                              </div>
                            </div>
                          </div>
                        ) : dashboardRoute == "proofOfLife" ? (
                          <div className="will3-dashboard-block-increase">
                            <div className="will3-dashboard-section">
                              <h2>Proof of Life</h2>
                              <div>
                                <p style={{ textAlign: "left", maxWidth: 1100 }}>
                                  {localProvider._lastBlockNumber < block ? (
                                    <p style={{ lineHeight: "24px", fontWeight: 400 }}>
                                      Your CoinWill shall carry out your wishes for your crypto assets in approximately{" "}
                                      <span style={{ color: "#0A64BC", fontWeight: "bold" }}>
                                        {dayTimer > 0 ? `${dayTimer} Days, ` : ``}
                                        {hourTimer > 0 ? `${hourTimer} Hours and ` : ``}
                                        {minuteTimer > 0 ? `${minuteTimer} Minutes` : ``}
                                      </span>
                                      . The time until your CoinWill disburses may vary based on the{" "}
                                      {targetNetwork.displayName} network's average block transaction speed. CoinWill
                                      disbursement will occur after the {targetNetwork.displayName} network's current
                                      block is greater than your CoinWill's disbursal block.
                                    </p>
                                  ) : isWill3Disbursed ? (
                                    <p style={{ fontWeight: 400 }}>
                                      Your CoinWill was disbursed in accordance with your wishes after{" "}
                                      {targetNetwork.displayName} Block{" "}
                                      {block.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.
                                    </p>
                                  ) : (
                                    <span>
                                      <p style={{ marginTop: 24, fontWeight: 400 }}>
                                        Your CoinWill is ready to be disbursed
                                      </p>
                                      <p style={{ marginTop: 24, fontWeight: 40 }}>
                                        Automatic disbursal is disabled during the CoinWill Beta period — to disburse
                                        your CoinWill manually,{" "}
                                        <a style={{ textDecoration: "underline" }} href="/disburse">
                                          click here
                                        </a>
                                      </p>
                                    </span>
                                  )}
                                </p>
                                <div style={{ textAlign: "center", margin: "24px 0px 24px 0px" }}></div>
                                {localProvider._lastBlockNumber < block ? (
                                  <p style={{ textAlign: "center", fontWeight: "500", color: "#07014F" }}>
                                    {block
                                      ? `Current Block: ${localProvider._lastBlockNumber.toLocaleString(
                                          "en-US",
                                        )} | Disbursal Block: ${block.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                      : ``}
                                  </p>
                                ) : (
                                  ``
                                )}
                              </div>
                              {localProvider._lastBlockNumber < block ? (
                                <Form
                                  name="sign"
                                  onFinish={onFinish}
                                  onFinishFailed={onFinishFailed}
                                  autoComplete="off"
                                >
                                  <div
                                    style={{
                                      margin: "auto",
                                      width: "80%",
                                      marginTop: 32,
                                      maxWidth: 400,
                                      textAlign: "center",
                                    }}
                                  >
                                    <Form.Item>
                                      {canIncreaseBlock ? (
                                        <Button
                                          className="connect-wallet-button"
                                          shape="round"
                                          size="large"
                                          htmlType="submit"
                                          style={{ color: "black" }}
                                        >
                                          Update Proof of Life
                                        </Button>
                                      ) : (
                                        <Tooltip title="You will be able to submit a Proof of Life update closer to your CoinWill's disbursement date">
                                          <Button
                                            shape="round"
                                            size="large"
                                            htmlType="submit"
                                            style={{ color: "black" }}
                                            disabled
                                          >
                                            Update Proof of Life
                                          </Button>
                                        </Tooltip>
                                      )}
                                    </Form.Item>
                                  </div>
                                </Form>
                              ) : (
                                ``
                              )}
                            </div>
                          </div>
                        ) : dashboardRoute == "notifications" ? (
                          <div>
                            <div className="will3-dashboard-section" style={{ textAlign: "center" }}>
                              <h2>Notifications</h2>
                              <div style={{ marginTop: 24, marginBottom: 24 }}>
                                <p
                                  className="create-section-description"
                                  style={{
                                    minWidth: "500px",
                                    maxWidth: "1000px",
                                    margin: "auto",
                                    marginBottom: 6,
                                  }}
                                >
                                  You are required to periodically re-sign your CoinWill before it disburses
                                </p>
                                <p
                                  className="create-section-description"
                                  style={{
                                    minWidth: "500px",
                                    maxWidth: "1000px",
                                    margin: "auto",
                                  }}
                                >
                                  Proof of Life notifications help you know when you need to check your CoinWill
                                </p>
                              </div>
                              <div style={{ marginBottom: 12, justifyContent: "center" }}>
                                <Tooltip title="Coming Soon">
                                  <Form.Item
                                    tooltip="Enable reminder emails to get notifications about when your CoinWill is disbursable and how to increase your CoinWill's disbursement block."
                                    label=""
                                    name="email"
                                    className="reminder-input-section"
                                    style={{ margin: "unset !important" }}
                                  >
                                    <Input
                                      placeholder="Your Email Address"
                                      prefix={
                                        <img style={{ height: 20, marginRight: 20, marginLeft: 10 }} src={mailIcon} />
                                      }
                                      className="user-reminder-input"
                                      type={"email"}
                                      disabled
                                    />
                                  </Form.Item>
                                </Tooltip>

                                <Tooltip title="Coming Soon">
                                  <Form.Item
                                    tooltip="Enable reminder text messages to get notifications about when your CoinWill is disbursable and how to increase your CoinWill's disbursement block."
                                    label=""
                                    name="phone"
                                    className="reminder-input-section"
                                    style={{ margin: "unset !important" }}
                                  >
                                    <Input
                                      placeholder="Your Phone Number"
                                      prefix={
                                        <img style={{ height: 20, marginRight: 20, marginLeft: 10 }} src={phoneIcon} />
                                      }
                                      controls={false}
                                      className="user-reminder-input"
                                      type={"text"}
                                      disabled
                                    />
                                  </Form.Item>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        ) : dashboardRoute == "cancelWill3" ? (
                          <div>
                            <div className="will3-dashboard-section" style={{ textAlign: "center" }}>
                              <h2>Cancel CoinWill</h2>
                              <div style={{ marginTop: 24, marginBottom: 24 }}>
                                <p
                                  className="create-section-description"
                                  style={{
                                    minWidth: "500px",
                                    maxWidth: "1000px",
                                    margin: "auto",
                                    marginBottom: 6,
                                  }}
                                >
                                  Cancelling your CoinWill removes all instructions for disbursing your assets and makes
                                  it so your CoinWill can never be disbursed
                                </p>
                                <p
                                  className="create-section-description"
                                  style={{
                                    minWidth: "500px",
                                    maxWidth: "1000px",
                                    margin: "auto",
                                  }}
                                >
                                  If you choose to cancel your CoinWill, you must create a new CoinWill to disburse your
                                  assets
                                </p>
                              </div>
                              <div style={{ marginBottom: 12, justifyContent: "center" }}>
                                <Button
                                  className="cancel-will3-button"
                                  style={{ height: "auto", padding: 14, width: "250px" }}
                                  shape="round"
                                  size="large"
                                  onClick={removeWill3}
                                >
                                  <p style={{ color: "white", margin: "auto", fontSize: 18 }}>
                                    Cancel CoinWill Instructions
                                  </p>
                                </Button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <Button>
                    <Link to="/create">
                      <p style={{ color: "white", margin: "auto" }}>Create Will</p>
                    </Link>
                  </Button>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}
