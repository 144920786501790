import { PageHeader } from "antd";
import React from "react";

// displays a page header

const will3CircleIcon = "assets/coinwill-logo-footer.png";

export default function Header(props) {
  const { will3Exists } = props;
  return (
    <span>
      <a href="./" rel="noopener noreferrer" style={{ float: "left" }}>
        <PageHeader avatar={{ src: will3CircleIcon, size: 60 }} subTitle=" " style={{ cursor: "pointer" }} />
      </a>
      <a href="./" className="page-header-link" rel="noopener noreferrer" style={{ float: "left" }}>
        <PageHeader subTitle="Home" />
      </a>
      <a href="./create" className="page-header-link" rel="noopener noreferrer" style={{ float: "left" }}>
        <PageHeader subTitle="Create" />
      </a>
      {will3Exists ? (
        <a href="./dashboard" className="page-header-link" rel="noopener noreferrer" style={{ float: "left" }}>
          <PageHeader subTitle="Dashboard" />
        </a>
      ) : (
        ``
      )}
    </span>
  );
}
