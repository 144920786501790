import { PageHeader } from "antd";
import React from "react";

import { Collapse, Row, Col } from "antd";

const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

const headerFontSize = "18px";
const contentFontSize = "14px";
const contentMargin = "0 0 0 24px";
const faqImage = "./../assets/faq.png";

export default function HowItWorks() {
  return (
    <div className="how-it-works-div">
      <h2 style={{ marginTop: 18, marginBottom: 24, fontWeight: "bold !important", fontSize: 36, color: "#0067C6" }}>
        Frequently Asked Questions
      </h2>
      <div style={{ margin: "0px 0px 2px 0px" }}></div>
      <Row>
        <Col span={12} style={{ textAlign: "right" }}>
          <img src={faqImage} className="how-it-works-image" style={{ objecFit: "contain !important" }} />
        </Col>
        <Col span={12}>
          <Collapse
            className="how-it-works"
            style={{ textAlign: "left" }}
            bordered={false}
            defaultActiveKey={["1"]}
            onChange={callback}
            accordion
            expandIconPosition="right"
          >
            <Panel style={{ fontSize: headerFontSize }} header="What is CoinWill?" key="1">
              <p style={{ margin: contentMargin, fontSize: contentFontSize }}>
                CoinWill is a trustless, on-chain estate planner that allows you to coordinate the distribution of your
                assets in the event of your passing.
              </p>
            </Panel>
            <Panel style={{ fontSize: headerFontSize }} header="Why should I have a CoinWill?" key="2">
              <p style={{ margin: contentMargin, fontSize: contentFontSize }}>
                It's important to plan for the unexpected. At CoinWill, we believe in supporting our loved ones in times
                of need. No matter what you choose to do, we recommend at least some level of estate planning for your
                crypto assets.
              </p>
            </Panel>
            <Panel style={{ fontSize: headerFontSize }} header="How do I use CoinWill?" key="3">
              <p style={{ margin: contentMargin, fontSize: contentFontSize }}>
                To designate assets to your beneficiaries, you must first approve the assets you would like to
                distribute at a later date and then create a list of beneficiary designations. In the event of your
                passing or loss of your wallet, these asset designations will be fulfilled and disbursed in accordance
                with your wishes.
              </p>
            </Panel>
            <Panel style={{ fontSize: headerFontSize }} header="How do you know if I'm dead or incapacitated?" key="4">
              <p style={{ margin: contentMargin, fontSize: contentFontSize }}>
                When setting up your CoinWill, you'll specify a date on which you'd like your CoinWill to trigger and
                disburse your designated assets. Within 60 days of this date, you'll have the ability to push the
                disbursement date forward; you can think of this as an attestation of life that will continually prolong
                the duration of your CoinWill. To remind you of this 60-day window, CoinWill offers opt-in reminder
                emails, text messages, and notifications so that you'll never inadvertently trigger the disbursement of
                your CoinWill.
              </p>
            </Panel>
            <Panel style={{ fontSize: headerFontSize }} header="When should I update my CoinWill?" key="5">
              <p style={{ margin: contentMargin, fontSize: contentFontSize }}>
                It's recommended that you revisit your CoinWill asset designations after any major event, such as
                marriage, divorce, the birth of a child or grandchild, or if one of your beneficiaries or executors
                passes away.
              </p>
            </Panel>
            {/*
            <Panel style={{ fontSize: headerFontSize }} header="Do I have to deposit my funds into CoinWill?" key="6">
              <p style={{ margin: contentMargin, fontSize: contentFontSize }}>
                No. CoinWill <u>never</u> has direct custody of your crypto assets.
              </p>
            </Panel>
            */}
            <Panel style={{ fontSize: headerFontSize }} header="How should I prepare my wallet for CoinWill?" key="7">
              <p style={{ margin: contentMargin, fontSize: contentFontSize }}>
                You can continue using your wallet as normal, or create one using a designated wallet that you leave
                inactive. It is recommended to have a CoinWill on every wallet you own in the case of unexpected life
                events.
              </p>
            </Panel>
            <Panel
              style={{ fontSize: headerFontSize }}
              header="Do I need a cryptocurrency wallet to use CoinWill?"
              key="8"
            >
              <p style={{ margin: contentMargin, fontSize: contentFontSize }}>
                Yes. You and your beneficiaries will need a cryptocurrency wallet to receive your disbursed assets upon
                release of your CoinWill. We would recommend using <a href="https://metamask.io/">MetaMask</a> for
                maximal compatibility with all of your assets, or at minimum a{" "}
                <a href="https://walletconnect.com/">WalletConnect</a>-compatible wallet for ease-of-use.
              </p>
            </Panel>
            <Panel
              style={{ fontSize: headerFontSize }}
              header="What happens to my assets when I'm not using them?"
              key="9"
            >
              <p style={{ margin: contentMargin, fontSize: contentFontSize }}>
                Your assets remain exactly as they are. CoinWill does not hold custody of your assets at any time.
              </p>
            </Panel>
            {/*
            <Panel
              style={{ fontSize: headerFontSize }}
              header="What side chains & L2s does this work on, if any?"
              key="10"
            >
              <p style={{ margin: contentMargin, fontSize: contentFontSize }}></p>
            </Panel>
            <Panel
              style={{ fontSize: headerFontSize }}
              header="What's the maximum amount of time I can set for my refresh interval?"
              key="11"
            >
              <p style={{ margin: contentMargin, fontSize: contentFontSize }}></p>
            </Panel>
            */}
            {/* 
        <Panel
          style={{ fontSize: headerFontSize }}
          bordered="false"
          header="Can I designate money to a charity in my CoinWill?"
          key="10"
        >
          <p style={{ margin: contentMargin }}>
            Absolutely! And we highly recommend it. We have a number of charitable options available through Gitcoin
            grants.
          </p>
        </Panel>
        */}
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}
