import { Button, Image, Layout, Row, Col } from "antd";
import React, { useState } from "react";
import { AppSteps, HowItWorks, Team } from "../components";
import { Link } from "react-router-dom";
import { isAllowed } from "../components/Whitelist";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_API_KEY, MIXPANEL_DEBUG } from "../constants";
mixpanel.init(MIXPANEL_API_KEY, { debug: MIXPANEL_DEBUG, ignore_dnt: true });

export default function UI({ address, web3Modal, loadWeb3Modal, setRoute, userSigner, isReleased }) {
  const titleImage = "./../assets/coinwill-logo.png";
  const homePageGif = "./../assets/digging-compressed.gif";
  const avaxLogo = "./../assets/avaxLogo.svg";
  const homePageHeaderImage = "./../assets/landing-image-2.png";

  const { Header, Content, Footer } = Layout;

  const sectionSeparatorMargin = "140px";

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Content
        style={{
          width: "100%",
          margin: "auto",
        }}
      >
        <div className="module">
          <div className={isReleased && isAllowed(address) ? "module-inside" : "module-inside-prerelease"}>
            <Row className="landing-page-top-row">
              <Col span={12} style={{ textAlign: "left", paddingTop: 48 }} className="landing-page-left">
                <h2 className="front-page-header-title">What happens to your Crypto Assets when you die?</h2>
                <h3
                  style={{
                    width: "100%",
                    minWidth: 400,
                    textAlign: "left",
                    margin: "auto",
                    marginTop: 50,
                    fontWeight: "normal",
                    maxWidth: 800,
                    color: "rgba(255, 255, 255, 0.71)",
                  }}
                >
                  Trustless estate planning for your crypto assets
                </h3>
                <h3
                  style={{
                    width: "100%",
                    minWidth: 400,
                    margin: "auto",
                    marginTop: 0,
                    fontWeight: "normal",
                    maxWidth: 800,
                    color: "rgba(255, 255, 255, 0.71)",
                  }}
                >
                  for the price of minting a NFT
                </h3>

                <div style={{ margin: 8, marginBottom: 40, marginTop: 24 }}>
                  {!isReleased || !isAllowed(address) ? (
                    <div
                      style={{
                        height: "auto",
                        padding: 14,
                        width: "20%",
                        minWidth: "200px",
                        maxWidth: "300px",
                        marginTop: 20,
                      }}
                    />
                  ) : !web3Modal.cachedProvider ? (
                    <Button
                      style={{
                        height: "auto",
                        padding: 18,
                        minWidth: "800px !important",
                        maxWidth: "900px !important",
                      }}
                      shape="round"
                      size="large"
                      className="connect-wallet-button-landing"
                      onClick={loadWeb3Modal}
                    >
                      <h6 style={{ color: "white", margin: "auto", fontSize: 24 }}>Connect Wallet</h6>
                    </Button>
                  ) : isAllowed(address) ? (
                    <Button
                      style={{ height: "auto", padding: 18, width: "20%", minWidth: "200px", maxWidth: "300px" }}
                      shape="round"
                      size="large"
                      className="connect-wallet-button-landing"
                    >
                      <Link
                        onClick={() => {
                          setRoute("/create");
                          window.scrollTo(0, 0);
                          mixpanel.track("Clicked Header CTA Button", {
                            page: "home",
                          });
                        }}
                        to="/create"
                      >
                        <h6 style={{ color: "white", margin: "auto", fontSize: 24 }}>Start Now</h6>
                      </Link>
                    </Button>
                  ) : (
                    <div
                      style={{
                        height: "auto",
                        padding: 14,
                        width: "100%",
                        minWidth: "200px",
                        maxWidth: "300px",
                        margin: "auto",
                        marginTop: 20,
                      }}
                    >
                      <h2 style={{ color: "white", textAlign: "center", width: "100% !important" }}>COMING SOON</h2>
                    </div>
                  )}
                </div>
              </Col>
              <Col span={12} className="landing-page-right">
                <img src={homePageHeaderImage} className="landing-page-image" />
              </Col>
            </Row>
          </div>
        </div>

        {/*
        <Row>
          <Col span={12}>
            <img src={homePageGif} style={{ border: "2px solid white", borderRadius: 8, width: "70%" }} />
          </Col>
          <Col span={12} style={{ textAlign: "left" }}>
            <img src={titleImage} style={{ width: "50%", marginBottom: 8 }} />

            <h3 style={{ marginTop: 12, fontWeight: "bold" }}>Smart contracts are forever. Life is not.</h3>
            <h6
              style={{
                width: "100%",
                textAlign: "left",
                margin: "auto",
                marginTop: 24,
                fontWeight: "normal",
                maxWidth: 1200,
              }}
            >
              What happens to your crypto assets after you die? Will3 monitors your life status and in the event of your
              death confirms and honors your last wishes. With Will3, you can choose who will receive your assets when
              you die and  your crypto ends up in the right hands.
            </h6>

            <div style={{ margin: 8, marginBottom: 60, marginTop: 24 }}>
              {!userSigner ? (
                <Button className="connect-wallet-button" onClick={loadWeb3Modal}>
                  <h5 style={{ color: "black" }}>Connect your wallet to continue</h5>
                </Button>
              ) : (
                <Button
                  style={{ height: "auto", width: "30%", padding: 14 }}
                  shape="round"
                  size="large"
                  className="connect-wallet-button"
                >
                  <Link
                    onClick={() => {
                      setRoute("/will3");
                    }}
                    to="/will3"
                  >
                    <h6 style={{ color: "black", margin: "auto" }}>Enter App</h6>
                  </Link>
                </Button>
              )}
            </div>
          </Col>
        </Row>
        */}

        <AppSteps web3Modal={web3Modal} setRoute={setRoute} loadWeb3Modal={loadWeb3Modal} />
        {/*
        <div style={{ height: 100, background: "#79BBFF" }} />
        */}
        <HowItWorks />
        {/*
        <Team />
        */}
      </Content>
    </Layout>
  );
}
