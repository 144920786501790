import { PageHeader, Card, Row, Col } from "antd";
import React from "react";

const { Meta } = Card;

// shows a display of the team
export default function PrivacyPolicy() {
  return (
    <div className="disclaimer-section">
      <h1 className="disclaimer-section-header">Privacy Policy</h1>
      <div>
        <div>
          <div>
            <div>
              <p>Last Updated: April 15, 2022</p>
            </div>
            <p>
              CoinWill provides solutions for end-of-life estate planning for your crypto assets. Created by Junoware,
              LLC (“CoinWill”), CoinWill allows users to records their wishes for their crypto assets into decentralized
              smart contracts, to be carried out at a later date.
            </p>
            <p>
              CoinWill is committed to protecting and respecting your privacy. This Privacy Policy (this “Policy”) sets
              out how we collect and process personal information about you when you visit our website at{" "}
              <strong>
                <a href="/">https://coinwill.xyz </a>
              </strong>
              or when you otherwise do business or make contact with us.
            </p>
            <p>
              <strong>1) What information do we collect?</strong>
            </p>
            <p>
              <strong>A. SUMMARY</strong>
            </p>
            <p>
              CoinWill collects data to enable us to operate the Site effectively, and to provide you with the best
              experiences on our website and our Service. You provide some of this data to us directly, such as when you
              register, subscribe to a newsletter, respond to a survey, make an enquiry through our website, contact us
              for support, or contact us as a prospective user, vendor, supplier, or consultant. We get some of your
              data by recording how you interact with our Service, for example, using technologies like cookies. We also
              obtain and process data in the context of making our Service available to you.
            </p>
            <p>
              <strong>B. LEARN MORE</strong>
            </p>
            <p>
              You have choices about the data we collect. When you are asked to provide personal data, you may decline.
              But if you choose not to provide data that is necessary to enable us to make the Service available to you,
              you may not be able to sign up for or use the Service. The data we collect depends on the context of your
              interactions with CoinWill, and the choices you make (including your privacy settings). The data we
              collect can include the following:
            </p>
            <p>
              Email and Metamask Wallet Address. We may collect your email address and your Metamask wallet address.
            </p>
            <p>
              Device and Usage information. We may collect data about your device and how you and your device interact
              with CoinWill and our Service. For example, we may collect your interactions on our website, your feature
              usage patterns, location data, and your interactions with us. We may also collect data about your device
              and the network you use to connect to our Service; this may include data such as your IP address, browser
              type, operating system, and referring URLs.
            </p>
            <p>
              <strong>2) What do we use your information for?</strong>
            </p>
            <p>
              <strong>A. SUMMARY</strong>
            </p>
            <p>
              We use the data we collect to operate our business, and to make the Service available to you. This
              includes using the data to improve our Service, and to personalize your experiences. We may also use the
              data to communicate with you to, among other things, inform you about your account, provide security
              updates, and give you information about the Service. We may also use the data to manage your email
              subscriptions, improve the relevance and security of our website, respond to user enquiries, send you
              periodic marketing communications about our Service, and improve the relevance of our advertising.
            </p>
            <p>
              <strong>B. LEARN MORE</strong>
            </p>
            <p>
              We use data to provide the Service to you, to improve the Service, and to perform essential business
              operations. This includes operating the Service, maintaining and improving the performance of the Service,
              developing new features, conducting research, and providing customer support. Examples of such uses
              include the following:
            </p>
            <p>
              Providing the Service. We use data to carry out your transactions with us and to make the Service
              available to you. In certain cases, the Service may include personalized features and recommendations to
              enhance your enjoyment, and automatically tailor your experience based on the data we have about you.
            </p>
            <p>
              Technical support. We use data to diagnose problems, and to provide customer care and other support
              services.
            </p>
            <p>
              Improving the Service. We use data to continually improve our website and our Service, including system
              administration, system security, and adding new features or capabilities.
            </p>
            <p>
              Business Operations. We use data to develop aggregate analyses and business intelligence that enable us to
              operate, protect, make informed decisions, and report on the performance of our business.
            </p>
            <p>Promotions. We may use your data to administer contests, promotions, surveys, or other site features.</p>
            <p>
              Improving Advertising Campaigns. We may use your data to improve our advertising campaigns, primarily in
              an effort to prevent targeting you with advertisement that are not relevant to you.
            </p>
            <p>
              Sending Periodic Emails. We may use your data to send you periodic emails. Depending on the marketing
              preferences you select on your privacy dashboard, we may send you occasional marketing emails about our
              Service or CoinWill Proof of Life notifications, which you can unsubscribe from at any time using the link
              provided in the message.
            </p>
            <p>
              Generally. We use data to respond to your enquiries and requests relating to our Service, to create and
              administer your account, and to provide us with information and access to resources that you have
              requested from us. We also use data for general business purposes, including, among other things, to
              improve customer service, to help us improve the content and functionality of our Service, to better
              understand our users, to protect against wrongdoing, to enforce our Terms of Use, and to generally manage
              our business.
            </p>
            <p>
              Communications. We use data we collect to communicate with you, and to personalize our communications with
              you. For example, we may contact you to discuss your account, to remind you about features of the Service
              that are available for your use, to update you about a support request, or to invite you to participate in
              a survey. Additionally, you can sign up for email subscriptions, and choose whether you want to receive
              marketing communications from us.
            </p>
            <p>
              <strong>3) How do we protect your information?</strong>
            </p>
            <p>
              We implement a variety of security measures to maintain the safety of your personal information when you
              enter, submit, or access your personal information. We offer the use of a secure server. All supplied
              sensitive information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our
              gateway providers database only to be accessible by those authorized with special access rights to such
              systems, and are required to keep the information confidential.
            </p>
            <p>
              <strong>
                4) How do we ensure that our processing systems remain confidential, resilient, and available?
              </strong>
            </p>
            <p>
              <strong>A. SUMMARY</strong>
            </p>
            <p>
              We implement a variety of measures to ensure that our processing systems remain confidential, resilient,
              and available. Specifically, we have implemented processes to help ensure high availability, business
              continuity, and prompt disaster recovery. We commit to maintaining strong physical and logical access
              controls, and conduct regular penetration testing to identify and address potential vulnerabilities.
            </p>
            <p>
              <strong>B. LEARN MORE</strong>
            </p>
            <p>
              High Availability. Every part of the Service utilizes properly-provisioned, redundant servers (e.g.,
              multiple load balancers, web servers, replica databases) in case of failure. We take servers out of
              operation as part of regular maintenance, without impacting availability.
            </p>
            <p>
              Business Continuity. We keep encrypted backups of data daily in multiple regions on Google Cloud Platform.
              While never expected, in the case of production data loss (i.e., primary data stores loss), we will
              restore organizational data from these backups.
            </p>
            <p>
              Disaster Recovery. In the event of a region-wide outage, we will bring up a duplicate environment in a
              different Google Cloud Platform region. Our operations team has extensive experience performing full
              region migrations.
            </p>
            <p>
              <strong>5) Do we use cookies?</strong>
            </p>
            <p>
              <strong>A. SUMMARY</strong>
            </p>
            <p>
              Yes. Cookies are small files that a site or its service provider transfers to your computers hard drive
              through your Web browser (if you allow) that enables the sites or service providers systems to recognize
              your browser and capture and remember certain information. You can choose to disable cookies, but if you
              do, your ability to use or access certain parts of the Service or of our website may be affected.
            </p>
            <p>
              <strong>B. LEARN MORE</strong>
            </p>
            <p>
              We use cookies and other similar identifiers to understand and save your preferences for future visits, to
              advertise to you on other sites, and to compile aggregate data about site traffic and site interaction so
              that we can offer better site experiences and tools in the future.
            </p>
            <p>
              You may refuse to accept cookies by activating the setting on your browser that allows you to refuse the
              setting of cookies. You can find information on popular browsers and how to adjust your cookie preferences
              at the following websites:
            </p>
            <ul>
              <li>
                <p>
                  <span style={{ textDecoration: "underline" }}>
                    <a
                      href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                      target="_blank"
                      rel="noopener"
                    >
                      Microsoft Internet Explorer
                    </a>
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: "underline" }}>
                    <a
                      href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                      target="_blank"
                      rel="noopener"
                    >
                      Mozilla Firefox
                    </a>
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: "underline" }}>
                    <a href="https://support.google.com/accounts/answer/61416" target="_blank" rel="noopener">
                      Google Chrome
                    </a>
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: "underline" }}>
                    <a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener">
                      Apple Safari
                    </a>
                  </span>
                </p>
              </li>
            </ul>
            <p>
              However, if you choose to disable cookies, you may be unable to access certain parts of our Site. A banner
              asking you to accept our cookies policy will be displayed upon the first visit to our website (or the
              first visit after you delete your cookies). Unless you have adjusted your browser setting so that it will
              refuse cookies and/or you have not accepted our cookies policy, our system will issue cookies when you log
              on to our site.
            </p>
            <p>
              Our web pages may contain electronic images known as web beacons (also called single-pixel gifs) that we
              use to help deliver cookies on our websites, and to count users who have visited those websites. We may
              also include web beacons in our promotional email messages or newsletters, to determine whether and when
              you open and act on them.
            </p>
            <p>
              In addition to placing web beacons on our own websites, we sometimes work with other companies to place
              our web beacons on their websites or in their advertisements. This helps us to develop statistics on how
              often clicking on an advertisement on a CoinWill website results in a purchase or other action on the
              advertiser's website.
            </p>
            <p>
              Finally, our Service may contain web beacons or similar technologies from third-party analytics providers
              that help us compile aggregated statistics about the effectiveness of our promotional campaigns or other
              operations. These technologies enable the analytics providers to set or read their own cookies or other
              identifiers on your device, through which they can collect information about your online activities across
              applications, websites or other products.
            </p>
            <p>
              <strong>6) Do we disclose any information to outside parties?</strong>
            </p>
            <p>
              <strong>A. SUMMARY</strong>
            </p>
            <p>
              We share your personal data with your consent, or as necessary to make the Service available to you. We
              also share your data with vendors working on our behalf; when required by law or to respond to legal
              process; to protect our customers; to protect lives; to maintain the security and integrity of our
              Service; and to protect our rights or our property.
            </p>
            <p>
              <strong>B. LEARN MORE</strong>
            </p>
            <p>
              We share your personal data with your consent, or as necessary to make the Service available to you. We
              also share personal data with vendors or agents working on our behalf for the purposes described in this
              Policy. For example, companies we have hired to provide cloud hosting services, off-site backups, and
              customer support may need access to personal data to provide those functions. In such cases, these
              companies are required to abide by our data privacy and security requirements and are not allowed to use
              personal data they receive from us for any other purpose. If you have questions or concerns about our
              vendors, feel free to contact us at{" "}
              <strong>
                <a href="mailto:contact@coinwill.xyz">contact@coinwill.xyz</a>
              </strong>
              .
            </p>
            <p>
              We may disclose your personal data as part of a corporate transaction such as a corporate sale, merger,
              reorganization, dissolution, or similar event. Finally, we will access, transfer, disclose, and/or
              preserve personal data, when we have a good faith belief that doing so is necessary to:
            </p>
            <p>(1) comply with applicable law or respond to valid legal process, judicial orders, or subpoenas;</p>
            <p>
              (2) respond to requests from public or governmental authorities, including for national security or law
              enforcement purposes;
            </p>
            <p>
              (3) protect the vital interests of our users, customers, or other third parties (including, for example,
              to prevent spam or attempts to defraud users of our Service, or to help prevent the loss of life or
              serious injury of anyone);
            </p>
            <p>
              (4) operate and maintain the security or integrity of our Service, including to prevent or stop an attack
              on our computer systems or networks;
            </p>
            <p>(5) protect the rights, interests or property of CoinWill or third parties;</p>
            <p>(6) prevent or investigate possible wrongdoing in connection with the Service; or</p>
            <p>
              (7) enforce our{" "}
              <a href="/tou">
                <strong>Terms of Use</strong>.
              </a>
            </p>
            <p>
              We may use and share aggregated non-personal information with third parties for marketing, advertising,
              and analytics purposes. We do not sell or trade your personal information to third parties.
            </p>
            <p>
              <strong>7) How to Access and Control Your Personal Data</strong>
            </p>
            <p>
              <strong>A. SUMMARY</strong>
            </p>
            <p>
              You can view, access, edit, delete, or request a copy of your personal data for many aspects of the
              Service via your user dashboard. You can also make choices about CoinWill's collection and use of your
              data. You can always choose whether you want to receive marketing communications from us. You can also opt
              out from receiving marketing communications from us by using the opt-out link on the communication, or by
              visiting your user dashboard.
            </p>
            <p>
              <strong>B. LEARN MORE</strong>
            </p>
            <p>Data Access. You can access your personal data on your account's user dashboard.</p>
            <p>
              Data Portability. You can request a copy of your personal data by submitting an email to us at{" "}
              <strong>
                <a href="mailto:contact@coinwill.xyz">contact@coinwill.xyz</a>
              </strong>{" "}
              and including “Please send me a copy of my personal data” in the “Subject” line. CoinWill will verify your
              ability to access that email, then send you a digital export of the data we hold that is associated with
              your email address. We will use reasonable efforts to respond to your request within 14 days, but in all
              events within 30 days of our receipt of the request. CoinWill may be limited in its ability to send
              certain personal data to you (e.g., the identification of your Metamask wallet).
            </p>
            <p>
              Data Erasure. You can delete your personal data on your account's user dashboard. Please be aware that we
              require certain information about you in order to make the Service available to you; this means that if
              you want to delete any of these critical pieces of personal data, you may be required to delete your
              entire profile and no longer be able to access or use the Service. Alternatively, you may request that
              CoinWill delete your personal data by submitting an email to us at{" "}
              <strong>
                <a href="mailto:contact@coinwill.xyz">contact@coinwill.xyz</a>{" "}
              </strong>
              and including “Please delete my personal data” in the “Subject” line. CoinWill will verify your ability to
              access that email, then delete the personal data associated with your email address. We will use
              reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our
              receipt of the request.
            </p>
            <p>
              Data Correction. You can modify your personal data on your account's user dashboard. Note that since some
              of the data we collect is specific to you - for example, your Metamask wallet address - you may not be
              able to modify this data without needing to create a new user profile.
            </p>
            <p>
              Your Communications Preferences. You can choose whether you wish to receive marketing communications from
              us. If you receive marketing communications from us and would like to opt out, you can do so by following
              the directions in that communication. You can also make choices about your receipt of marketing
              communications by signing into your account, and viewing and managing your communication permissions in
              your account's user dashboard, where you can update contact information, manage your contact preferences,
              opt out of email subscriptions, and choose whether to share your contact information with CoinWill and our
              partners. Alternatively, you can request that we withdraw consent to use your personal data by submitting
              an email to us at{" "}
              <strong>
                <a href="mailto:contact@coinwill.xyz">contact@coinwill.xyz</a>
              </strong>{" "}
              and including “Please withdraw my consent for marketing communications” in the “Subject” line. CoinWill
              will verify your ability to access that email, then update our systems to remove your email address from
              the system we use to send marketing communications. We will use reasonable efforts to respond to your
              request within 14 days, but in all events within 30 days of our receipt of the request. Please note that
              these choices do not apply to mandatory communications that are part of the Service, or to surveys or
              other informational communications that have their own unsubscribe method.
            </p>
            <p>
              <strong>8) Third Party Links</strong>
            </p>
            <p>
              Occasionally, at our discretion, we may include or offer third party products or services on our website
              or through our Service. If you access other websites using the links provided, the operators of these
              websites may collect information from you that will be used by them in accordance with their privacy
              policies. These third party sites have separate and independent privacy policies. We, therefore, have no
              responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to
              protect the integrity of our site and welcome any feedback about these sites.
            </p>
            <p>
              <strong>9) Where we Store and Process Personal Data; International Transfers</strong>
            </p>
            <p>
              Personal data collected by CoinWill may be stored and processed in the United States or in any other
              country where CoinWill or its affiliates, subsidiaries or service providers maintain facilities. The
              storage location(s) are chosen in order to operate efficiently, to improve performance, and to create
              redundancies in order to protect the data in the event of an outage or other problem. We take steps to
              ensure that the data we collect is processed according to the provisions of this Policy, and the
              requirements of applicable law wherever the data is located.
            </p>
            <p>
              We transfer personal data from the European Economic Area and Switzerland to other countries, some of
              which have not been determined by the European Commission to have an adequate level of data protection.
              When we engage in such transfers, we use a variety of legal mechanisms, including contracts, to help
              ensure your rights and protections travel with your data. To learn more about the European Commission's
              decisions on the adequacy of the protection of personal data in the countries where CoinWill processes
              personal data, please visit:{" "}
              <span style={{ textDecoration: "underline" }}>
                <a href="https://ec.europa.eu/info/law/law-topic/data-protection_en" target="_blank" rel="noopener">
                  https://ec.europa.eu/info/law/law-topic/data-protection_en
                </a>
              </span>
            </p>
            <p>
              <strong>10) Data Retention</strong>
            </p>
            <p>
              <strong>A. SUMMARY</strong>
            </p>
            <p>
              We may retain your personal information as long as you continue to use the Service, have an account with
              us, or for as long as is necessary to fulfill the purposes outlined in this Policy. You can ask to close
              your account by contacting us as described above, and we will delete your personal information on request.
              We may, however, retain personal information for an additional period as is permitted or required under
              applicable laws, for legal, tax, or regulatory reasons, or for legitimate and lawful business purposes.
            </p>
            <p>
              <strong>B. LEARN MORE</strong>
            </p>
            <p>
              We will retain your personal data for as long as necessary to make the Service available to you, or for
              other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing
              our agreements. Because these needs can vary for different types of data, actual retention periods can
              vary significantly. The criteria we use to determine the retention periods include:
            </p>
            <ul>
              <li>
                <p>
                  How long is the personal data needed to make the Service available to you and/or operate our business?
                  This includes such things such as maintaining and improving the performance of the Service, keeping
                  our systems secure, and maintaining appropriate business and financial records. This is the general
                  rule that establishes the baseline for most data retention periods.
                </p>
              </li>
              <li>
                <p>
                  Is there an automated control, such as in your user dashboard, that enables you to access and delete
                  the personal data at any time? If there is not, a shortened data retention time will generally be
                  adopted.
                </p>
              </li>
              <li>
                <p>
                  Is the personal data of a sensitive type? If so, a shortened retention time would generally be
                  appropriate.
                </p>
              </li>
              <li>
                <p>
                  Has the user provided consent for a longer retention period? If so, we will retain the data in
                  accordance with your consent.
                </p>
              </li>
              <li>
                <p>
                  Is CoinWill subject to a legal, contractual, or similar obligation to retain the data? Examples can
                  include mandatory data retention laws in the applicable jurisdiction, government orders to preserve
                  data relevant to an investigation, or data that must be retained for the purposes of litigation.
                </p>
              </li>
            </ul>
            <p>
              <strong>11) Changes to our Privacy Policy</strong>
            </p>
            <p>
              We will update this privacy statement when necessary to reflect customer feedback and changes to our
              Service. When we post changes to this statement, we will revise the "last updated" date at the top of the
              statement. If there are material changes to the statement or in how CoinWill will use your personal data,
              we will notify you either by prominently posting a notice of such changes before they take effect or by
              sending you a notification directly. We encourage you to periodically review this privacy statement to
              learn how CoinWill is protecting your information.
            </p>
            <p>
              <strong>12) How to Contact Us</strong>
            </p>
            <p>
              If you have a technical or support question, please send us an email at{" "}
              <a href="mailto:contact@coinwill.xyz">
                <strong>contact@coinwill.xyz</strong>
              </a>
            </p>
            <p>
              If you have a privacy concern, complaint, or a question for the Data Protection Officer of CoinWill,
              please contact us by sending us an email at{" "}
              <a href="mailto:contact@coinwill.xyz">
                <strong>contact@coinwill.xyz</strong>
              </a>
              . We will respond to questions or concerns within 30 days.
            </p>
            <p>
              Unless otherwise stated, CoinWill is a data controller for personal data we collect through the Service
              subject to this statement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
