import { Alert, Button, Tooltip, Spin, Steps, Layout, Row, Col } from "antd";
import "antd/dist/antd.css";
import { useBalance, useContractLoader, useContractReader, useGasPrice, useUserProviderAndSigner } from "eth-hooks";
import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Fragment } from "react";
import { BrowserRouter, Link, Route, Switch, useHistory } from "react-router-dom";
import { Account, Contract, Header, PageLinks } from "./components";
import { INFURA_ID, NETWORK, NETWORKS } from "./constants";
import { web3Modal } from "./context";
import externalContracts from "./contracts/external_contracts";
// contracts
import deployedContracts from "./contracts/hardhat_contracts.json";
import { Transactor } from "./helpers";
// import Hints from "./Hints";
import { UI, Landing, ExampleUI } from "./views";
import { ApproveUtil } from "./components";
import DisbursementInput from "./components/CreateWill3/DisbursementInput";
import { InfoCircleOutlined } from "@ant-design/icons/lib/icons";
import Dashboard from "./components/Dashboard";
import TermsOfUse from "./components/TermsOfUse";
import PrivacyPolicy from "./components/PrivacyPolicy";

import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import ReleaseDisbursement from "./components/ReleaseDisbursement";
import "./App.css";
import mixpanel from "mixpanel-browser";

const { ethers } = require("ethers");
const { Step } = Steps;
const { Footer } = Layout;

const dayTimerSoonThreshold = 30;

const will3Logo = "./../assets/coinwill-logo.png";
const will3LogoFooter = "./../assets/coinwill-logo-footer.png";

const twitterIcon = "./../assets/twitter.png";
const discordIcon = "./../assets/discord.png";

const createWill3HeaderImage = "./../assets/create-will3-header-image.png";

/*
    Welcome to 🏗 scaffold-eth !

    Code:
    https://github.com/scaffold-eth/scaffold-eth

    Support:
    https://t.me/joinchat/KByvmRe5wkR-8F_zz6AjpA
    or DM @austingriffith on twitter or telegram

    You should get your own Infura.io ID and put it in `constants.js`
    (this is your connection to the main Ethereum network for ENS etc.)


    🌏 EXTERNAL CONTRACTS:
    You can also bring in contract artifacts in `constants.js`
    (and then use the `useExternalContractLoader()` hook!)
*/

/// 📡 What chain are your contracts deployed to?
const targetNetwork = NETWORKS.goerli; // <------- select your target frontend network (localhost, fujiAvalanche, rinkeby, xdai, mainnet)

// 😬 Sorry for all the console logging
const DEBUG = false;
const NETWORKCHECK = true;
const USE_BURNER_WALLET = false;
const RELEASED = true;

// 🛰 providers
if (DEBUG) console.log("📡 Connecting to Mainnet Ethereum");
// const mainnetProvider = getDefaultProvider("mainnet", { infura: INFURA_ID, etherscan: ETHERSCAN_KEY, quorum: 1 });
// const mainnetProvider = new InfuraProvider("mainnet",INFURA_ID);
//
// attempt to connect to our own scaffold eth rpc and if that fails fall back to infura...
// Using StaticJsonRpcProvider as the chainId won't change see https://github.com/ethers-io/ethers.js/issues/901
const scaffoldEthProvider = navigator.onLine
  ? new ethers.providers.StaticJsonRpcProvider("https://rpc.scaffoldeth.io:48544")
  : null;
const poktMainnetProvider = navigator.onLine
  ? new ethers.providers.StaticJsonRpcProvider(
      "https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406",
    )
  : null;
const mainnetInfura = navigator.onLine
  ? new ethers.providers.StaticJsonRpcProvider("https://mainnet.infura.io/v3/" + INFURA_ID)
  : null;
// ( ⚠️ Getting "failed to meet quorum" errors? Check your INFURA_ID
// 🏠 Your local provider is usually pointed at your local blockchain
const localProviderUrl = targetNetwork.rpcUrl;
// as you deploy to other networks you can set REACT_APP_PROVIDER=https://dai.poa.network in packages/react-app/.env
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : localProviderUrl;
if (DEBUG) console.log("🏠 Connecting to provider:", localProviderUrlFromEnv);
const localProvider = new ethers.providers.StaticJsonRpcProvider(localProviderUrlFromEnv);

// 🔭 block explorer URL
const blockExplorer = targetNetwork.blockExplorer;
const addressExplorer = targetNetwork.addressExplorer;

function App(props) {
  notification.config({
    maxCount: 1,
  });

  const loading = useRef(true);
  const mainnetProvider =
    poktMainnetProvider && poktMainnetProvider._isProvider
      ? poktMainnetProvider
      : scaffoldEthProvider && scaffoldEthProvider._network
      ? scaffoldEthProvider
      : mainnetInfura;

  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  const price = useExchangeEthPrice(targetNetwork, mainnetProvider);

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork, "fast");
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider, USE_BURNER_WALLET);
  const userSigner = userProviderAndSigner.signer;

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner, gasPrice);

  // Faucet Tx can be used to send funds from the faucet
  const faucetTx = Transactor(localProvider, gasPrice);

  // 🏗 scaffold-eth is full of handy hooks like this one to get your balance:
  const yourLocalBalance = useBalance(localProvider, address);

  // Just plug in different 🛰 providers to get your balance on different chains:
  const yourMainnetBalance = useBalance(mainnetProvider, address);

  // const contractConfig = useContractConfig();

  const contractConfig = { deployedContracts: deployedContracts || {}, externalContracts: externalContracts || {} };

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider, contractConfig);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(userSigner, contractConfig, localChainId);

  // EXTERNAL CONTRACT EXAMPLE:
  //
  // If you want to bring in the mainnet DAI contract it would look like:
  const mainnetContracts = useContractLoader(mainnetProvider, contractConfig);

  /*
  // If you want to call a function on a new block
  useOnBlock(mainnetProvider, () => {
    console.log(`⛓ A new mainnet block is here: ${mainnetProvider._lastBlockNumber}`);
  });
  */

  // Then read your DAI balance like:
  const myMainnetDAIBalance = useContractReader(mainnetContracts, "DAI", "balanceOf", [
    "0x34aA3F359A9D614239015126635CE7732c18fDF3",
  ]);

  // keep track of a variable from the contract in the local React state:
  // const purpose = useContractReader(readContracts, "YourContract", "purpose");

  /*
  const addressFromENS = useResolveName(mainnetProvider, "austingriffith.eth");
  console.log("🏷 Resolved austingriffith.eth as:",addressFromENS)
  */

  //
  // 🧫 DEBUG 👨🏻‍🔬
  //
  useEffect(() => {
    if (
      DEBUG &&
      mainnetProvider &&
      address &&
      selectedChainId &&
      yourLocalBalance &&
      yourMainnetBalance &&
      readContracts &&
      writeContracts &&
      mainnetContracts
    ) {
      console.log("_____________________________________ 🏗 scaffold-eth _____________________________________");
      console.log("🌎 mainnetProvider", mainnetProvider);
      console.log("🏠 localChainId", localChainId);
      console.log("👩‍💼 selected address:", address);
      console.log("🕵🏻‍♂️ selectedChainId:", selectedChainId);
      console.log("💵 yourLocalBalance", yourLocalBalance ? ethers.utils.formatEther(yourLocalBalance) : "...");
      console.log("💵 yourMainnetBalance", yourMainnetBalance ? ethers.utils.formatEther(yourMainnetBalance) : "...");
      console.log("📝 readContracts", readContracts);
      console.log("🌍 DAI contract on mainnet:", mainnetContracts);
      console.log("💵 yourMainnetDAIBalance", myMainnetDAIBalance);
      console.log("🔐 writeContracts", writeContracts);
    }
  }, [
    mainnetProvider,
    address,
    selectedChainId,
    yourLocalBalance,
    yourMainnetBalance,
    readContracts,
    writeContracts,
    mainnetContracts,
  ]);

  let networkDisplay = "";
  if (localChainId && selectedChainId && localChainId !== selectedChainId) {
    const networkSelected = NETWORK(selectedChainId);
    const networkLocal = NETWORK(localChainId);
    if (selectedChainId === 1337 && localChainId === 31337) {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 60, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network ID"
            description={
              <div>
                You have <b>chain id 1337</b> for localhost and you need to change it to <b>31337</b> to work with
                HardHat.
                <div>(MetaMask -&gt; Settings -&gt; Networks -&gt; Chain ID -&gt; 31337)</div>
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    } else {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 60, top: 60, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network"
            description={
              <div>
                <div>
                  Please switch to the <b>{networkLocal && networkLocal.displayName}</b> network
                </div>
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    }
  } else if (web3Modal.cachedProvider) {
    networkDisplay = (
      <div
        style={{
          zIndex: -1,
          position: "absolute",
          right: 154,
          top: 34,
          padding: 16,
          paddingTop: 32,
          color: targetNetwork.color,
        }}
      >
        {targetNetwork.displayName}
      </div>
    );
  }

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
      loading.current = false;
    }
  }, [loadWeb3Modal]);

  const [route, setRoute] = useState();
  useEffect(() => {
    setRoute(window.location.pathname);
  }, [setRoute]);

  let faucetHint = "";
  const faucetAvailable = localProvider && localProvider.connection && targetNetwork.name.indexOf("local") !== -1;

  const [faucetClicked, setFaucetClicked] = useState(false);
  if (
    !faucetClicked &&
    localProvider &&
    localProvider._network &&
    localProvider._network.chainId === 31337 &&
    yourLocalBalance &&
    ethers.utils.formatEther(yourLocalBalance) <= 0
  ) {
    faucetHint = (
      <div style={{ padding: 16 }}>
        <Button
          type="primary"
          onClick={() => {
            faucetTx({
              to: address,
              value: ethers.utils.parseEther("0.01"),
            });
            setFaucetClicked(true);
          }}
        >
          💰 Grab funds from the faucet ⛽️
        </Button>
      </div>
    );
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [will3Exists, setwill3Exists] = useState(false);

  useEffect(() => {
    const getWill3 = async () => {
      if (!writeContracts.Will3Master) {
        return setwill3Exists(false);
      }
      const result = await writeContracts.Will3Master.getWill3(address);

      console.log(result);

      if (result.length != 0) {
        setwill3Exists(true);
      } else {
        setwill3Exists(false);
      }
    };
    getWill3();
  }, [address, writeContracts]);

  useEffect(() => {
    if (will3Exists && route === "/create") {
      notification.info({
        message: "Go To Your Dashboard",
        description: "Your wallet has an existing CoinWill",
        placement: "topRight",
        onClick: () => {
          notification.destroy();
          window.location.href = "/dashboard";
        },
        duration: 0,
      });
    }
  }, [will3Exists, route]);

  return (
    <div className="App">
      <div style={{ marginTop: "40px" }}></div>
      <div id="particle-screen"></div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/create">
            {address && writeContracts && tx && (
              <Fragment>
                <div style={{ width: "100%", margin: "auto", marginTop: 24, marginBottom: 0 }}>
                  <div className="create-will3-header">
                    <div className="create-will3-header-inside" style={{ paddingBottom: 64 }}>
                      <Row>
                        <Col span={14} className="create-page-left">
                          <h1 className="create-will3-header-title-text">Create Your CoinWill</h1>
                          <h4 className="create-will3-subtitle">Secure Crypto Estate Planning</h4>
                          <h5 className="create-will3-description">Set up your CoinWill in 3 easy steps:</h5>
                          <div className="create-will3-steps">
                            <h5 className="create-will3-step">
                              1. Approve tokens / NFTs and create your asset disbursement instructions
                            </h5>
                            <h5 className="create-will3-step">
                              2. Set your trigger, which determines when your assets are disbursed
                            </h5>
                            <h5 className="create-will3-step">
                              3. Enable email or text notifications for your CoinWill
                            </h5>
                          </div>
                        </Col>
                        <Col span={10} className="create-page-right">
                          <img src={createWill3HeaderImage} className="create-will3-header-image" />
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="create-will3-blue-section">
                    <div className="create-will3-section first-create-will3-section">
                      <h2 className="create-section-header-title">Approve</h2>
                      <div className="blue-line-divider" />
                      <h6 className="create-section-description">
                        Approving your assets allows CoinWill to carry out your wishes and disburse your assets to your
                        beneficiaries. Each asset must be approved, and permission can be added or removed at any time.
                      </h6>
                      <ApproveUtil
                        chainId={targetNetwork.moralisId}
                        moralisChainId={targetNetwork.moralisChainId}
                        userAddress={address}
                        signer={userSigner}
                        provider={localProvider}
                        address={readContracts.Will3Master ? readContracts.Will3Master.address : "null"}
                        blockExplorer={addressExplorer}
                        appId={targetNetwork.moralisAppId}
                        serverUrl={targetNetwork.moralisServerUrl}
                        route={route}
                      />
                    </div>
                  </div>
                  <DisbursementInput
                    tx={tx}
                    writeContracts={writeContracts}
                    userAddress={address}
                    setRoute={setRoute}
                    localProvider={localProvider}
                    targetNetwork={targetNetwork}
                    mainnetProvider={mainnetProvider}
                  />
                </div>
              </Fragment>
            )}
          </Route>

          <Route exact path="/pp">
            <PrivacyPolicy />
          </Route>

          <Route exact path="/tou">
            <TermsOfUse />
          </Route>

          {/*
          <Route exact path="/exampleui">
            <ExampleUI />
          </Route>
            */}

          <Route exact path="/dashboard">
            {address && writeContracts && tx && targetNetwork && (
              <Dashboard
                chainId={targetNetwork.moralisId}
                tx={tx}
                writeContracts={writeContracts}
                localProvider={localProvider}
                userAddress={address}
                address={readContracts.Will3Master ? readContracts.Will3Master.address : "null"}
                blockExplorer={addressExplorer}
                appId={targetNetwork.moralisAppId}
                serverUrl={targetNetwork.moralisServerUrl}
                route={route}
                will3Exists={will3Exists}
                targetNetwork={targetNetwork}
                mainnetProvider={mainnetProvider}
                addressExplorer={addressExplorer}
                dayTimerSoonThreshold={dayTimerSoonThreshold}
                signer={userSigner}
              />
            )}
          </Route>

          <Route exact path="/disburse">
            {writeContracts && tx && (
              <ReleaseDisbursement localProvider={localProvider} tx={tx} writeContracts={writeContracts} />
            )}
          </Route>

          <Route exact path="/debug">
            <Contract
              name="Will3Master"
              price={price}
              signer={userSigner}
              provider={localProvider}
              address={address}
              blockExplorer={blockExplorer}
              contractConfig={contractConfig}
              addressExplorer={addressExplorer}
            />
          </Route>

          {/*
          <Route path="/">
            <Landing
              web3Modal={web3Modal}
              address={address}
              userSigner={userSigner}
              loadWeb3Modal={loadWeb3Modal}
              setRoute={setRoute}
            />
          </Route>
          */}

          <Route path="/">
            <UI
              web3Modal={web3Modal}
              address={address}
              userSigner={userSigner}
              loadWeb3Modal={loadWeb3Modal}
              setRoute={setRoute}
              isReleased={RELEASED}
            />
          </Route>
        </Switch>
      </BrowserRouter>

      <div className="page-header">
        {/*
        <Alert
          message={
            <span>
              We value your feedback — please complete our{" "}
              <a
                href="https://forms.gle/DVKwk6NzQhzQKE7f7"
                target="_blank"
                onClick={() =>
                  mixpanel.track("Clicked Survey Button", {
                    page: route,
                  })
                }
              >
                short survey
              </a>{" "}
              about your experience with CoinWill
            </span>
          }
          type="success"
        />
        */}
        {/* 👨‍💼 Your account is in the top right with a wallet at connect options */}
        <Header will3Exists={will3Exists} />
        {/* <PageLinks /> */}
        {networkDisplay}
        <Account
          address={address}
          localProvider={localProvider}
          userSigner={userSigner}
          mainnetProvider={mainnetProvider}
          price={price}
          web3Modal={web3Modal}
          loadWeb3Modal={loadWeb3Modal}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
          blockExplorer={blockExplorer}
          addressExplorer={addressExplorer}
          will3Exists={will3Exists}
        />
        {faucetHint}
        <br />
        {/* <div>
          <button onClick={() => authenticate({ signingMessage: "Hello World!" })}>Authenticate</button>
        </div>
        <div>
          <button onClick={() => logout()}>Logout</button>
        </div> */}
      </div>
      <Footer className={route == "/create" ? "will3-page-footer will3-page-footer-create-will3" : "will3-page-footer"}>
        {/*
        <img style={{ marginBottom: "8px" }} width={70} height={20} src={will3Text} />
        */}
        <Row justify="left" style={{ marginBottom: 54, marginTop: 36 }}>
          <Col span={5} style={{ margin: "auto" }}>
            <a href="/">
              <img src={will3Logo} style={{ height: 80 }} />
            </a>
          </Col>
          <Col span={5} style={{ margin: "auto" }}>
            <div className="left-align">Email Address</div>
            <h6 className="left-align">
              <a className="footer-link" href="mailto:contact@coinwill.xyz">
                contact@coinwill.xyz
              </a>
            </h6>
          </Col>
          <Col span={5} style={{ margin: "auto" }}>
            <h6 className="left-align">
              <a className="footer-link" href="/tou">
                Terms of Use
              </a>
            </h6>
          </Col>
          <Col span={5} style={{ margin: "auto" }}>
            <h6 className="left-align">
              <a className="footer-link" href="/pp">
                Privacy Policy
              </a>
            </h6>
          </Col>
        </Row>
        <div style={{ marginBottom: 24 }}>
          {/*
          <a href="https://discord.gg/T5NWFVc2vy" target="_blank">
            <img style={{ padding: "0px 4px" }} height={30} src={discordIcon} />
          </a>{" "}
          */}
          <a href="https://twitter.com/CoinWillxyz" target="_blank">
            <img style={{ padding: "0px 4px" }} height={25} src={twitterIcon} />
          </a>
        </div>
        <div style={{ marginTop: 24 }}>© 2022 Junoware. All Rights Reserved.</div>
      </Footer>
    </div>
  );
}

export default App;
